:root {
  --vh: 100%;
}
/* body */
body {
  padding-bottom: env(safe-area-inset-bottom);
  min-height: calc(var(--vh, 1vh) * 100 - env(safe-area-inset-bottom));
  overflow: hidden;
}

/* Overall layout */
/* container-fluid--cp-150 */
@media(min-width:1200px)
{
  .container-fluid--cp-150
{
  padding: 0px 150px !important;
}
}
@media(min-width:992px) and (max-width:1200px)
{
  .container-fluid--cp-150
{
  padding: 0px 100px !important;
}
}
@media(min-width:767px) and (max-width:992px)
{
  .container-fluid--cp-150
{
  padding: 0px 60px !important;
}
}
@media(min-width:567px) and (max-width:767px)
{
  .container-fluid--cp-150
{
  padding: 0px 30px !important;
}
}
@media(max-width:567px) 
{
  .container-fluid--cp-150
{
  padding: 0px 15px !important;
}
.container-fluid--cp-150.a
{
  padding: 0px 15px !important;
}

.p-sm-0
{
  padding: 0px !important;
}
.chat-body
{
  background-color:#ffffff !important;
  min-height: calc(var(--vh, 1vh) * 100 - env(safe-area-inset-bottom));
}

}

.App {
  background-color: #3a3939 !important;
  min-height: calc(var(--vh, 1vh) * 100 - env(safe-area-inset-bottom));
}

.chat-body {
  background-color: #333333;
  min-height: calc(var(--vh, 1vh) * 100 - env(safe-area-inset-bottom));
}

.chatbox {
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100 - env(safe-area-inset-bottom));
  max-width: 100%;
  margin: auto;
  background-color: #f5f5f5;
  border-radius: 8px;
  justify-content: end;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.logo
{
  width: 40%;
  height: 90%;
}
.logo-arrow
{
  position: relative;
  z-index: 1;
  right: 27px;
  /* width: 39px; */
  height: 27px;
  top: 2px;
}
.logo-right
{
  /* height: 150px; */
  max-width: 100%;
}

.App-header {
  background-color: #fff;
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  border-radius: 8px 8px 0 0;
  font-size: 24px;
  text-align: center;
}

/* Messages styling */
.messages {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-image: url(/./src/assets/images/bg/bg.png);
  
}

.message {
  padding: 10px 20px;
  border-radius: 20px;
  max-width: 70%;
  word-wrap: break-word;
  animation: slideIn 0.5s ease-out forwards;
}

.message.user {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}

.message.gpt {
  background-color: #e9ecef;
  color: black;
  align-self: flex-start;
}

/* Input area styling */
.chat-input-area {
  display: flex;
  padding: 10px;
  background-color: white;
  border-radius: 50px;
  border-top: 1px solid #dee2e6;

}

@media(max-width:400px)
{
  .chat-input{
    padding: 10px 0px 10px 10px !important;
  }
}
.chat-input {
  flex: 1 1;
  padding: 10px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  margin-right: 10px;
  background: #f3f3f3;
}

.send-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding:10px 15px;
  border-radius: 100%;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.send-button:active {
  transform: scale(0.95);
}

/* Animations */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Scrollbar styling for messages */
.messages::-webkit-scrollbar {
  width: 5px;
}

.messages::-webkit-scrollbar-thumb {
  background: #007bff;
  border-radius: 10px;
}

.messages::-webkit-scrollbar-thumb:hover {
  background: #0056b3;
}

.role-toggle-button {
  margin-left: 20px;
  padding: 5px 10px;
  font-size: 16px;
  color: #007bff;
  background-color: white;
  border: 2px solid #007bff;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
}

.record-btn {
  background-color: #007bff; /* Red color to indicate recording */
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%; /* Circular button */
  font-size: 24px;
  cursor: pointer;
  margin-left: 10px;
}

.record-btn:hover {
  background-color: #007bff; /* Slightly darker on hover */
}
.send-img
{
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: cover;
}
.tech-img
{
  width: 30px;
  height: 20px;
}
.col-md-block
  {
    display:none;
  }
@media (max-width:992px)
{
  .col-md-none
  {
   display: none;
  }
  .col-md-block
  {
    display:block;
  }
}



/* Estilo iOS */
.switch__container {
  margin: 5px 10px;
 max-width: 120px;
 margin-left: auto;
 
}

.switch {
  visibility: hidden;
  position: absolute;
  margin-left: -9999px;
}

.switch + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

.switch--shadow + label {
  padding: 2px;
  max-width: 110px;
  height: 45px;
  background-color: #dddddd;
  border-radius: 60px;
}
.switch--shadow + label:before,
.switch--shadow + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
.switch--shadow + label:before {
  right: 1px;
  background-color: #f1f1f1;
  border-radius: 60px;
  transition:  0.4s;
  /* background-image: url(../src/assets/images/icons/para.png); */
  background-repeat: no-repeat;
  background-size: cover;
  /* width: 100px;
  height: 57px; */

}
.switch--shadow + label:after {
  width: 42px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.4s;
  background-image: url(../src/assets/images/icons/para.png);

  background-size: contain;
  background-repeat: no-repeat;


}
.switch--shadow:checked + label:before {
  background: linear-gradient(-45deg, #1CA6B7, #00B59C) !important;

}

.switch--shadow:checked + label:after {
  transform: translateX(60px);
  background-size: contain !important;
  width: 48px !important;
  background-repeat: no-repeat !important;
  background-color: #fff !important;
  background:url(./assets/images/icons/stu.png) ;
  border-radius: 50%;
}

/* Estilo Flat */
.switch--flat + label {
  padding: 2px;
  width: 120px;
  height: 60px;
  background-color: #dddddd;
  border-radius: 60px;
  transition:  0.4s;
}
.switch--flat + label:before,
.switch--flat + label:after {
  display: block;
  position: absolute;
  content: "";
}
.switch--flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  border-radius: 60px;
  transition:  0.4s;
}
.switch--flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 56px;
  background-color: #dddddd;
  border-radius: 52px;
  transition: margin 0.4s,  0.4s;
  background: url(../src/assets/images/icons/para.png);
  content: "";
}
.switch--flat:checked + label {
  background-color: #8ce196;
  
}
.switch--flat:checked + label:after {
  margin-left: 60px;
  background-color: #8ce196;
}

.switch--shadow:checked + label:after .logo-arrow
{
display:none;
}
@media (min-width:567px) and (max-width:992px)
{
  .logo
  {
    width: 75%;
  }
}
@media (max-width:567px)
{
  .logo
  {
    width: 100%;
  }
  .switch--shadow + label:before
  {

  }
  .switch--shadow:checked + label:after
  {
    transform: translateX(42px);
  }
  .switch--shadow + label{
    width: 80%;
  }
  .App-header
  {
    padding: 5px 20px;
  }
}

